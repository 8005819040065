import { getOperationAST } from 'graphql';
import { ValueOrPromise } from 'value-or-promise';
import { memoize1 } from '@graphql-tools/utils';
import { execute, flattenIncrementalResults, subscribe } from './execute.js';
export function normalizedExecutor(args) {
  const operationAST = getOperationAST(args.document, args.operationName);
  if (operationAST == null) {
    throw new Error('Must provide an operation.');
  }
  if (operationAST.operation === 'subscription') {
    return subscribe(args);
  }
  return new ValueOrPromise(() => execute(args)).then(result => {
    if ('initialResult' in result) {
      return flattenIncrementalResults(result);
    }
    return result;
  }).resolve();
}
export const executorFromSchema = memoize1(function executorFromSchema(schema) {
  return function schemaExecutor(request) {
    return normalizedExecutor({
      schema,
      document: request.document,
      variableValues: request.variables,
      operationName: request.operationName,
      rootValue: request.rootValue,
      contextValue: request.context,
      signal: request.signal || request.info?.signal
    });
  };
});