import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { GraphQLError } from 'graphql';
import { Dictionary } from 'lodash';
import { Document, DocumentClass } from 'src/app/graphql/frontend-data-graphql';

import { VerificationComponentType } from './document.reducer';

export const DocumentUIActions = createActionGroup({
  source: 'Document UI',
  events: {
    Select: props<{ id: string | null }>(),
    GotoNext: props<{ prevId: string }>(),
    Reload: props<{ id: string }>(),
    InitExtractionVerification: props<{ documentClasses: Dictionary<DocumentClass> }>()
  }
});

export const DocumentApiActions = createActionGroup({
  source: 'Document API',
  events: {
    FindOneSucceeded: props<{ item: Document; documentClasses: DocumentClass[]; verificationType: VerificationComponentType }>(),
    FindParentsSucceeded: props<{ documentId: string; parents: Document[] }>(),
    LockOneSucceeded: props<{ lockedBy: string; lockedUntil: Date }>(),
    Created: props<{ item: Document }>(),
    UpdatedOne: props<{ item: Document }>(),
    DeletedOne: props<{ id: string }>(),
    RequestFailed: props<{ errors: readonly GraphQLError[] }>()
  }
});
