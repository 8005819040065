export function createDeferred() {
  if (Promise.withResolvers) {
    return Promise.withResolvers();
  }
  let resolve;
  let reject;
  const promise = new Promise((_resolve, _reject) => {
    resolve = _resolve;
    reject = _reject;
  });
  return {
    promise,
    resolve,
    reject
  };
}