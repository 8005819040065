import { parse } from 'graphql';
function isURL(str) {
  try {
    const url = new URL(str);
    return !!url;
  } catch (e) {
    return false;
  }
}
export const asArray = fns => Array.isArray(fns) ? fns : fns ? [fns] : [];
const invalidDocRegex = /\.[a-z0-9]+$/i;
export function isDocumentString(str) {
  if (typeof str !== 'string') {
    return false;
  }
  // XXX: is-valid-path or is-glob treat SDL as a valid path
  // (`scalar Date` for example)
  // this why checking the extension is fast enough
  // and prevent from parsing the string in order to find out
  // if the string is a SDL
  if (invalidDocRegex.test(str) || isURL(str)) {
    return false;
  }
  try {
    parse(str);
    return true;
  } catch (e) {
    if (!e.message.includes('EOF') && str.replace(/(\#[^*]*)/g, '').trim() !== '' && str.includes(' ')) {
      throw new Error(`Failed to parse the GraphQL document. ${e.message}\n${str}`);
    }
  }
  return false;
}
const invalidPathRegex = /[‘“!%^<>`\n]/;
/**
 * Checkes whether the `str` contains any path illegal characters.
 *
 * A string may sometimes look like a path but is not (like an SDL of a simple
 * GraphQL schema). To make sure we don't yield false-positives in such cases,
 * we disallow new lines in paths (even though most Unix systems support new
 * lines in file names).
 */
export function isValidPath(str) {
  return typeof str === 'string' && !invalidPathRegex.test(str);
}
export function compareStrings(a, b) {
  if (String(a) < String(b)) {
    return -1;
  }
  if (String(a) > String(b)) {
    return 1;
  }
  return 0;
}
export function nodeToString(a) {
  let name;
  if ('alias' in a) {
    name = a.alias?.value;
  }
  if (name == null && 'name' in a) {
    name = a.name?.value;
  }
  if (name == null) {
    name = a.kind;
  }
  return name;
}
export function compareNodes(a, b, customFn) {
  const aStr = nodeToString(a);
  const bStr = nodeToString(b);
  if (typeof customFn === 'function') {
    return customFn(aStr, bStr);
  }
  return compareStrings(aStr, bStr);
}
export function isSome(input) {
  return input != null;
}
export function assertSome(input, message = 'Value should be something') {
  if (input == null) {
    throw new Error(message);
  }
}