import { ScrollingModule } from '@angular/cdk/scrolling';
import { AsyncPipe, CommonModule, JsonPipe } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { LetModule } from '@delon/abc/let';
import { NoticeIconModule } from '@delon/abc/notice-icon';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import omit from 'lodash/omit';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzStringTemplateOutletDirective } from 'ng-zorro-antd/core/outlet';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzI18nService } from 'ng-zorro-antd/i18n';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzNotificationModule, NzNotificationService } from 'ng-zorro-antd/notification';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { MarkdownModule } from 'ngx-markdown';
import { merge, Observable, take } from 'rxjs';
import { NotificationConfirm, NotificationLevel } from 'src/app/graphql/frontend-data-graphql';
import { FormatTimeDistancePipe } from 'src/app/shared/pipes/format-time-distance.pipe';
import { AuthService } from 'src/app/shared/services/auth.service';
import { NotificationConfirmUiActions } from 'src/app/state/notification-confirms/notification-confirm.actions';
import {
  selectAllNotificationConfirms,
  selectCreatedNotificationConfirms,
  selectUnreadNotificationConfirmCount,
  selectUnreadStickyNotificationConfirms
} from 'src/app/state/notification-confirms/notification-confirm.selectors';
import { NotificationConfirmService } from 'src/app/state/notification-confirms/notification-confirm.service';

import { NotificationDataSource } from './scrollable-data-source';

@Component({
  selector: 'header-notify',
  template: `
    <div
      class="alain-default__nav-item"
      nz-dropdown
      [nzDropdownMenu]="taskMenu"
      nzTrigger="click"
      nzPlacement="bottomRight"
      (nzVisibleChange)="change()">
      <nz-badge [nzCount]="(count$ | async) ?? 0" nzSize="small">
        <i nz-icon nzType="bell" class="alain-default__nav-item-icon"></i>
      </nz-badge>
    </div>

    <nz-dropdown-menu #taskMenu="nzDropdownMenu">
      <div nz-menu class="wd-lg dropdown">
        @if (loading) {
          <div class="mx-lg p-lg"><nz-spin /></div>
        } @else {
          <nz-card [nzTitle]="notificationCardTitle" nzBordered="false" class="ant-card__body-nopadding">
            <ng-template #notificationCardTitle>
              {{ 'BASIC.NOTIFICATION.notifications' | translate }}
              <nz-tag [nzColor]="'gold'">Beta</nz-tag>
            </ng-template>
            <div class="scrollable-container">
              <cdk-virtual-scroll-viewport [itemSize]="54" class="demo-infinite-container">
                <nz-list>
                  <nz-list-item
                    *cdkVirtualFor="let confirm of dataSource; index as i; trackBy: trackConfirm"
                    (click)="markAsRead(confirm)"
                    [class.notice-icon__item-read]="confirm.confirmed">
                    @if (confirm.notification; as notification) {
                      <nz-list-item-meta [nzTitle]="nzTitle" [nzDescription]="nzDescription" [nzAvatar]="nzAvatar">
                        <ng-template #nzTitle>
                          <ng-container *nzStringTemplateOutlet="notification.title[0].message; context: { $implicit: notification }">
                            {{ notification.title[0].message }}
                          </ng-container>
                          <span *ngIf="notification.sticky" nz-icon nzType="pushpin" nzTheme="outline"></span>
                        </ng-template>
                        <ng-template #nzAvatar>
                          <nz-list-item-meta-avatar>
                            <div class="ant-list-item-meta-avatar">
                              @switch (notification.level) {
                                @case (NotificationLevel.Info) {
                                  <span
                                    nz-icon
                                    nzType="info-circle"
                                    nzTheme="twotone"
                                    class="ant-notification-notice-icon ant-notification-notice-icon-info"></span>
                                }
                                @case (NotificationLevel.Warning) {
                                  <span
                                    nz-icon
                                    nzType="exclamation-circle"
                                    nzTheme="twotone"
                                    [nzTwotoneColor]="'#FFA500'"
                                    class="ant-notification-notice-icon ant-notification-notice-icon-warning"></span>
                                }
                                @case (NotificationLevel.Error) {
                                  <span
                                    nz-icon
                                    nzType="close-circle"
                                    nzTheme="twotone"
                                    [nzTwotoneColor]="'#ff0000'"
                                    class="ant-notification-notice-icon ant-notification-notice-icon-error"></span>
                                }
                              }
                            </div>
                          </nz-list-item-meta-avatar>
                        </ng-template>
                        <ng-template #nzDescription>
                          @if (notification!.message[0].message) {
                            <div class="notice-icon__item-desc">
                              <ng-container
                                *nzStringTemplateOutlet="notification!.message[0].message; context: { $implicit: notification }">
                                <markdown [data]="notification.message[0].message" />
                              </ng-container>
                            </div>
                          }
                          @if (notification.created) {
                            <div class="notice-icon__item-time">{{ notification.created | formatTimeDistance }}</div>
                          }
                        </ng-template>
                      </nz-list-item-meta>
                    }
                  </nz-list-item>
                </nz-list>
              </cdk-virtual-scroll-viewport>
            </div>
            <div class="notice-icon__clear" (click)="markAllAsRead()">{{ 'BASIC.NOTIFICATION.markAllAsRead' | translate }}</div>
          </nz-card>
        }
      </div>
    </nz-dropdown-menu>

    <ng-template #banner let-notificationconfirm="data" let-notification>
      @if (notificationconfirm.notification; as not) {
        <div class="ant-notification-notice-content">
          <div class="ant-notification-notice-content">
            <div [class.ant-notification-notice-with-icon]="true">
              @switch (not.level) {
                @case (NotificationLevel.Info) {
                  <span
                    nz-icon
                    nzType="info-circle"
                    nzTheme="twotone"
                    class="ant-notification-notice-icon ant-notification-notice-icon-info"></span>
                }
                @case (NotificationLevel.Warning) {
                  <span
                    nz-icon
                    nzType="exclamation-circle"
                    nzTheme="twotone"
                    [nzTwotoneColor]="'#FFA500'"
                    class="ant-notification-notice-icon ant-notification-notice-icon-warning"></span>
                }
                @case (NotificationLevel.Error) {
                  <span
                    nz-icon
                    nzType="close-circle"
                    nzTheme="twotone"
                    [nzTwotoneColor]="'#ff0000'"
                    class="ant-notification-notice-icon ant-notification-notice-icon-error"></span>
                }
              }
              <div class="ant-notification-notice-message">
                <ng-container *nzStringTemplateOutlet="not.title[0].message">
                  <div [innerHTML]="not.title[0].message"></div>
                </ng-container>
              </div>
              <div class="ant-notification-notice-description">
                <ng-container *nzStringTemplateOutlet="not.message[0].message">
                  <markdown>{{ not.message[0].message }}</markdown>
                </ng-container>
              </div>
              <span class="ant-notification-notice-btn">
                <button nz-button nzSize="small" nzType="primary" (click)="markAsRead(notificationconfirm); notification.close()">
                  <span nz-icon nzType="check-circle" nzTheme="outline"></span>
                  {{ 'BASIC.NOTIFICATION.markAsRead' | translate }}
                </button>
              </span>
            </div>
          </div>
        </div>
      }
    </ng-template>
  `,
  styleUrl: `./header-notify.component.less`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NoticeIconModule,
    AsyncPipe,
    NzNotificationModule,
    JsonPipe,
    NzButtonModule,
    CommonModule,
    NzStringTemplateOutletDirective,
    NzIconModule,
    LetModule,
    MarkdownModule,
    NzDropDownModule,
    NzBadgeModule,
    NzSpinModule,
    NzCardModule,
    NzAvatarModule,
    NzGridModule,
    NzListModule,
    NzTagModule,
    FormatTimeDistancePipe,
    ScrollingModule,
    TranslateModule
  ]
})
export class HeaderNotifyComponent implements OnInit {
  @ViewChild('banner', { static: false })
  template?: TemplateRef<{}>;

  constructor(
    private notificationConfirmService: NotificationConfirmService,
    private translate: TranslateService,
    private nzNotifications: NzNotificationService,
    private auth: AuthService
  ) {}

  private readonly msg = inject(NzMessageService);
  private readonly nzI18n = inject(NzI18nService);
  private readonly cdr = inject(ChangeDetectorRef);
  private readonly store = inject(Store);
  NotificationLevel = NotificationLevel;

  readonly data$: Observable<NotificationConfirm[]> = this.store.select(selectAllNotificationConfirms);
  dataSource: NotificationDataSource;

  count$ = this.store.select(selectUnreadNotificationConfirmCount);
  loading = false;

  ngOnInit() {
    merge(
      this.store.select(selectUnreadStickyNotificationConfirms).pipe(take(3)),
      this.store.select(selectCreatedNotificationConfirms)
    ).subscribe(notifications => {
      notifications.forEach(n => {
        this.nzNotifications.template(this.template!, {
          nzData: n,
          nzAnimate: true,
          nzDuration: n.notification?.sticky ? 0 : undefined
        });
      });
    });

    this.store.dispatch(NotificationConfirmUiActions.findAllTriggered({ filter: {} }));

    this.dataSource = new NotificationDataSource(this.store);
  }

  markAllAsRead(): void {
    this.store.dispatch(NotificationConfirmUiActions.markAsReadTriggered());
    this.msg.success(`Alle als gelesen markiert`);
  }

  markAsRead(notificationConfirm: NotificationConfirm): void {
    this.msg.success(
      `'${notificationConfirm.notification?.title[0].message}' als ${!notificationConfirm.confirmed ? 'gelesen' : 'ungelesen'} markiert.`
    );
    // const notification: NotificationConfirm = res.item as NotificationConfirm;
    // console.log(res);
    this.store.dispatch(
      NotificationConfirmUiActions.updateOneTriggered({
        id: notificationConfirm.id,
        update: {
          ...omit(notificationConfirm as NotificationConfirm, ['id', 'created', 'updated', 'notification']),
          confirmed: !notificationConfirm.confirmed
        }
      })
    );
  }

  change() {}

  trackConfirm(index: number, item: NotificationConfirm) {
    return item.id;
  }
}
