import { getAbortPromise } from '@graphql-tools/utils';
/**
 * This function transforms a JS object `Record<string, Promise<T>>` into
 * a `Promise<Record<string, T>>`
 *
 * This is akin to bluebird's `Promise.props`, but implemented only using
 * `Promise.all` so it will work with any implementation of ES6 promises.
 */
export async function promiseForObject(object, signal) {
  const resolvedObject = Object.create(null);
  const promises = Promise.all(Object.entries(object).map(async ([key, value]) => {
    resolvedObject[key] = await value;
  }));
  if (signal) {
    const abortPromise = getAbortPromise(signal);
    return Promise.race([abortPromise, promises]).then(() => resolvedObject);
  }
  return promises.then(() => resolvedObject);
}